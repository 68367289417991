<template>
    <div class="p-d-flex p-flex-column p-jc-start minusMargin" style="height: calc(100% + 26px);">
        <div class="p-d-flex p-jc-between minusMargin2 listPage" style="height: calc(100% + 16px);">
            <div class="p-col-12 p-d-flex p-col-customPad p-flex-column p-px-0 p-py-0">
                <TreeTable
                    :value="filterItems"
                    :scrollable="true"
                    :lazy="true"
                    :paginator="true"
                    scroll-height="calc(100vh - 450px)"
                    class="p-datatable-customers p-datatable-striped organization-list"
                    :class="{ showFilters }"
                    :rows="pageSize"
                    :rowsPerPageOptions="rowsPerPageOptions"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate=""
                    :totalRecords="totalRecords"
                    :pageLinkSize="4"
                    selectionMode="single"
                    resizable-columns
                    :filters="filters"
                    filterMode="lenient"
                    @node-expand="rowExpandHandler"
                    @row-dblclick="rowDblClickHandler"
                    @page="onPage"
                    :contentStyle='{ overflow: "visible" }'
                >
                    <template #header>
                        <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                            <div class="p-col-8 p-p-0 p-d-flex p-ai-center elementMargin">
                                <h5 class="p-d-inline p-mb-0 p-pt-0">Своды по организациям</h5>
                            </div>
                            <div class="p-col-5 p-p-0 p-d-flex p-ai-center table-active-period">
                                <Dropdown
                                    v-model="selectedForm"
                                    placeholder="Форма"
                                    :options="formList"
                                    option-label="name"
                                    data-key="id"
                                    class="customDropDown p-column-filter"
                                    filter
                                    @change="clearData"
                                   /><!-- @change="selectedMainFilter"
                                /-->
                                <Dropdown
                                    v-model="selectedPeriod"
                                    :options="activePeriodList"
                                    optionLabel="label"
                                    placeholder="Активный период"
                                    class="p-column-filter"
                                    @click.stop
                                    @change="clearData"
                                  /><!--  @change="selectedMainFilter"
                                /-->
                                <Calendar v-show="selectedPeriod.value === 'range'"
                                          id="calendarStart"
                                          v-model="rangeStart"
                                          :showIcon="true"
                                          dateFormat="dd.mm.yy"
                                          :locale="rusCalendareLocale"
                                          @change="clearData"
                                       /><!--   @change="selectedMainFilter"
                                          @date-select="selectedMainFilter"
                                /-->
                                <Calendar v-show="selectedPeriod.value === 'range'"
                                          id="calendarEnd"
                                          v-model="rangeEnd"
                                          :showIcon="true"
                                          dateFormat="dd.mm.yy"
                                          :locale="rusCalendareLocale"
                                          @change="clearData"
                                         /><!-- @change="selectedMainFilter"
                                          @date-select="selectedMainFilter"
                                /-->
                                <div class="p-p-0 p-new-btn">
                                    <Button @click="selectedMainFilter" class="p-button">Применить фильтр</Button>
                                </div>
                            </div>
                            <div class="p-col-5 p-p-0 p-d-flex p-jc-end p-ai-center table-global-search">
                                <!--div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                                    <span class="p-float-label">
                                        <InputText type="text"
                                            v-model="filters.name"
                                            placeholder="Поиск"
                                            @keydown="onInputKeydown"
                                            @change="selectedFilter"
                                        />
                                    </span>
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-search"></i>
                                    </span>
                                </div-->
                                <div class="p-p-0 p-new-btn">
                                    <Button @click="aggregateAll" class="p-button" :disabled="!canAggregate">Сделать свод</Button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        <span v-if="acceptFilter">
                            По выбранным параметрам своды не найдены.
                        </span>
                        <span v-else style="height: 400px !important; display: block; padding-top: 160px; text-align: center; font-size: 24px; line-height: 41px;">
                            Выберите Форму и Период.<br>Нажмите кнопку "Применить фильтр".
                        </span>
                    </template>
                    <template #loading>Загрузка сводов. Пожалуйста, подождите...</template>
                    <Column
                        header="ID"
                        header-class='entry-header-id table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-id table-body'
                        field="incrementId"
                        :expander="true"
                        filterMatchMode="contains"
                    >
                        <template #body='slotProps'>
                            <span>{{ slotProps.node.data?.incrementId }}</span>
                        </template>
                        <template #filter v-show='showFilters'>
                            <InputText
                                v-show='showFilters'
                                type="text"
                                v-model="filters.incrementId"
                                class="p-column-filter p-my-2"
                                placeholder="Поиск"
                                @keydown="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Название"
                        header-class='entry-header-name table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-name table-body'
                        field="organization"
                    >
                        <template #body='slotProps'>
                            <span :title="slotProps.node.data.organization">{{ slotProps.node.data.organization }}</span>
                        </template>
                        <template #filter v-show='showFilters'>
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.organizations"
                                :options="organizationsList"
                                :showToggleAll="true"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="publicName"
                                display="chip-count"
                                filter
                                class="multiselect-custom"
                                @change="selectedOrganizations($event)"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Тип"
                        header-class='entry-header-org table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-org table-body'
                        field="type"
                    >
                        <template #body='slotProps'>
                            <span>{{ slotProps.node.data.type }}</span>
                        </template>
                        <template #filter v-show='showFilters'>
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.types"
                                :options="types"
                                :showToggleAll="false"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="name"
                                display="chip-count"
                                class="multiselect-custom"
                                :disabled="filters.organizations && filters.organizations.length > 0"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Подчинение"
                        header-class='entry-header-org table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-org table-body'
                        field="subordination"
                    >
                        <template #body='slotProps'>
                            <span>{{ slotProps.node.data.subordination }}</span>
                        </template>
                        <template #filter v-show='showFilters'>
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.subordination"
                                :options="subordinations"
                                :showToggleAll="false"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="name"
                                display="chip-count"
                                class="multiselect-custom"
                                :disabled="filters.organizations && filters.organizations.length > 0"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Расположение"
                        header-class='entry-header-org table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-org table-body'
                        field="location"
                    >
                        <template #body='slotProps'>
                            <span>{{ slotProps.node.data.location }}</span>
                        </template>
                        <template #filter v-show='showFilters'>
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.location"
                                :options="locations"
                                :showToggleAll="false"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="name"
                                display="chip-count"
                                class="multiselect-custom"
                                :disabled="filters.organizations && filters.organizations.length > 0"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Округ"
                        header-class='entry-header-org table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-org table-body'
                        field="district"
                    >
                        <template #body='slotProps'>
                            <span>{{ slotProps.node.data.district }}</span>
                        </template>
                        <template #filter v-show='showFilters'>
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.district"
                                :options="locations"
                                :showToggleAll="false"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="name"
                                display="chip-count"
                                class="multiselect-custom"
                                :disabled="filters.organizations && filters.organizations.length > 0"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Период"
                        header-class='entry-header-period table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-period table-body'
                        field="periodType"
                        filterMatchMode="contains"
                    >
                        <template #body='slotProps'>
                            <span>{{ slotProps.node.data.periodType }}</span>
                        </template>
                        <template #filter v-show="showFilters">
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.periods"
                                :options="periods"
                                :showToggleAll="true"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="name"
                                display="chip-count"
                                class="multiselect-custom"
                                filter
                                @change="selectedPeriods($event)"
                            />
                        </template>
                    </Column>
                    <Column
                        header=""
                        header-class="entry-header-empty table-header"
                        body-class="p-text-nowrap p-text-truncate entry-body-empty table-body"
                        field="empty">
                        <template #body="slotProps">
                            <span v-if="slotProps.node.data.empty" class="pi pi-file"></span>
                        </template>
                    </Column>
                    <Column
                        header="Статус"
                        header-class='entry-header-status table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-status table-body'
                        field="status"
                    >
                        <template #body='slotProps'>
                            <span
                                :title="getStatus(slotProps).title"
                                :style="`background: ${getStatus(slotProps).bgColor}; color: ${getStatus(slotProps).textColor}; padding: 4px; border-radius: 4px;`"
                            >{{ getStatus(slotProps).label }}</span>
                        </template>
                        <template #filter v-show="showFilters">
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.status"
                                :options="entryStatuses"
                                :showToggleAll="false"
                                data-key="label"
                                option-value="value"
                                placeholder="Поиск"
                                option-label="label"
                                display="chip-count"
                                class="multiselect-custom"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header=""
                        header-class="p-text-center"
                        body-style="position: relative; width: 52px; text-align: center;"
                        header-style="width: 70px;"
                    >
                        <template #header>
                            <Button
                                icon="pi pi-filter"
                                :class="filterClasses"
                                class="p-button-rounded p-button-outlined filter-btn"
                                style="margin-right: 4px;"
                                @click="showFilters = !showFilters"
                            />
                            <Button
                                icon="pi pi-times p-button-icon"
                                :class="filterClasses"
                                class="p-button-rounded p-button-outlined filter-btn"
                                @click="clearFilters"
                            />
                        </template>
                        <template v-if='showThreePointsMenu(listMenuItemsComputed)' #body="slotProps">
                            <div
                                class="p-panel-header-icon p-link"
                                @click.stop="toggleRowMenu($event, slotProps)"
                                v-if="slotProps.node.menuActive"
                            >
                                <span class="pi pi-ellipsis-h"></span>
                            </div>
                            <Menu
                                class="redLastListElement"
                                :ref="`listMenu${slotProps.node.key}`"
                                :model="menuItems(listMenuItemsComputed)"
                                :popup="true"
                                :baseZIndex="10"
                            />
                        </template>
                    </Column>
                    <template #paginatorLeft>
                        <div class='p-col-6'></div>
                    </template>
                </TreeTable>
            </div>
            <acceptFormDialog
                :showAcceptDialog="showAcceptFormDialog"
                :title="formTitle"
                :worksheets="reviewWorksheets"
                :acceptedWorksheetIds="acceptedWorksheetIds"
                :declinedWorksheetIds="declinedWorksheetIds"
                :acceptedGroups="acceptedGroupIds"
                :declinedGroups="declinedGroupIds"
                :comments="worksheetComments"
                @acceptForm="acceptForm"
                @closeAcceptFormDialog="closeAcceptFormDialog"
            />
        </div>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import {
    aggregateDistribution,
    approveDistributionReview,
    getDistributionListData,
    getDistributionWorksheetsStatuses,
    revokeDistributionApproval,
    sendDistributionReview,
    sendUnloadingData,
    submitDistributionReview,
    findOrganizations
} from '@/api/form/formsDistributions';
import { DISTRIBUTION_STATUSES, XSL_TEMPLATE_ID } from '@/constants/distributions';
import { USER_PERMISSIONS_MAP, FORM_PERMISSIONS_MAP, MAX_PAGE_SIZE } from '@/constants/common';
import { jsonApiListParser } from '@/main/utils/common';
import { getOrganizations } from '@/api/organization';
import { getOrganizationId } from '@/api/authData';
import { getFormGroupPermissions } from '@/api/form';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { aggregateByFilter, getTemplateData } from '@/api/form/formsTemplates';
import { getAllForms } from '@/api/form';
import { rusCalendareLocale } from '@/main/calendareLocales';
import { getAllIntervalPeriods } from '@/api/form/formsPeriods';//import { getFormAssignments } from '@/api/form/formAssignments';
import acceptFormDialog from '@/components/dataEntry/dialogs/acceptFormDialog';

const {
    formRead,
    formReadOwn,
    formVerificationRules,
    formWrite,
    formWriteOwn,
    formReview,
    formApprove,
    organizationReadAll
} = USER_PERMISSIONS_MAP

const {
    read,
    write,
    report,
    review,
    approve
} = FORM_PERMISSIONS_MAP

export default {
    name: 'AggregateList',
    mixins: [requestToastHandler],
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],

    activated() {
        if (this.dataLoaded) {
            this.$emit('loadingChange');
        }
    },

    components: {
        acceptFormDialog
    },

    data() {
        this.entryStatuses = DISTRIBUTION_STATUSES;
        this.pageSize = MAX_PAGE_SIZE;
        this.rusCalendareLocale = rusCalendareLocale;

        return {
            currentTemplateId: null,
            firstGroup: null,
            filters: {
                name: '',
                organizations: [],
                periods: [],
                subordination: [],
                location: [],
                district: [],
                status: [],
                params: {
                    organization: {
                        operation: '$in',
                        values: []
                    },
                    period: {
                        operation: '$in',
                        values: []
                    }
                }
            },
            distributionFilter: {},
            acceptFilter: false,
            showFilters: false,
            dataLoaded: false,
            worksheets: [],
            reviewWorksheets: [],
            statuses: [],
            formTitle: '',

            activePeriodList: [
                { value: 'day', label: 'Текущий день' },
                { value: 'week', label: 'Текущая неделя' },
                { value: 'month', label: 'Текущий месяц' },
                { value: 'year', label: 'Текущий год' },
                { value: 'all', label: 'Все периоды' },
                { value: 'future', label: 'Будущие периоды' },
                { value: 'pastMonth', label: 'Прошлый месяц' },
                { value: 'pastYear', label: 'Прошлый год' },
                { value: 'range', label: 'Диапазон' },
            ],
            selectedPeriod: { value: 'week', label: 'Текущая неделя' },

            organizationsList: null,
            formList: null,
            selectedForm: null,
            selectedAllOrganizations: 0,
            selectedAllPeriods: 0,
            isRightAggregate: false,
            filterInputTimeout: null,
            distributionId: '',
            acceptDistributions: [],
            distributions: null,
            dataEntryList: [],
            included: [],
            editRow: null,
            arrayLength: 5,
            new: [],
            types: [],
            currentPage: 1,
            totalRecords: null,
            entryStatuses: DISTRIBUTION_STATUSES,
            worksheetsStatus: [],
            canAccept: false,
            canDecline: false,
            canApprove: false,
            canCancel: false,
            canReport: true,
            canReview: false,
            showAcceptFormDialog: false,
            acceptedWorksheetIds: [],
            declinedWorksheetIds: [],
            acceptedGroupIds: [],
            declinedGroupIds: [],
            worksheetComments: [],
            subordinations: null,
            locations: null,
            periods: null,
            rangeStart: null,
            rangeEnd: null,
            formGroupPermissions: [],
            acceptGroupPersmissions: [],
            approveGroupPersmissions: []
        }
    },
    computed: {
        ...mapGetters('auth', [
            'currentUser',
            'accessibleItems',
            'formPermissionsObject',
            'userPermissionsObject'
        ]),
        ...mapGetters(['rowsPerPageOptions', 'aggregateFilter']),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        filterItems() {
            return this.dataEntryList;
        },
        showAllDistributions() {
            return [ formRead, formWrite, formReview, formApprove, organizationReadAll, formVerificationRules ].some(p => this.userPermissionsObject[p]);
        },
        canAggregate() {
            return [ formReadOwn, formVerificationRules ].some(p => this.userPermissionsObject[p]) && this.selectedForm && this.distributions?.length;
        },
        showArchiveOrganizations() {
            return [ formRead, formWrite ].some(p => this.userPermissionsObject[p]);
        },
        listMenuItemsComputed() {
            return [
                {
                    label: 'Сформировать свод',
                    icon: 'icon pi-fw custom-summary',
                    command: () => {
                        this.createAggregate(this.distributionId, this.firstGroup);
                    },
                    permissionAccess: [
                        formReadOwn,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Скачать XLS',
                    icon: 'pi icon custom-xls-file',
                    command: () => {
                        this.startUnloading(this.distributionId, XSL_TEMPLATE_ID);
                    },
                    visible: this.canReport,
                    permissionAccess: []
                },
                {
                    label: 'Отправить на проверку',
                    icon: 'pi icon custom-status-change',
                    command: () => {
                        this.sendReview();
                    },
                    visible: this.canReview,
                    permissionAccess: [
                        formWriteOwn,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Принять',
                    icon: 'pi icon custom-status-change',
                    command: () => {
                        this.showAcceptForm();
                    },
                    visible: this.canAccept,
                    permissionAccess: [
                        formReview,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Отклонить',
                    icon: 'pi icon custom-status-change',
                    command: () => {
                        this.showAcceptForm();
                    },
                    visible: this.canDecline,
                    permissionAccess: [
                        formReview,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Утвердить',
                    icon: 'pi icon custom-status-change',
                    command: () => {
                        this.approveForm();
                    },
                    visible: this.canApprove,
                    permissionAccess: [
                        formApprove,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Отменить утверждение',
                    icon: 'pi icon custom-status-change',
                    command: () => {
                        this.revokeApproval();
                    },
                    visible: this.canCancel,
                    permissionAccess: [
                        formApprove,
                        formVerificationRules
                    ]
                }
            ]
        }
    },
    methods: {
        ...mapMutations(['saveAggregateFilter']),
        ...mapActions(['initAggregateFilter']),
        menuItems(menu) {
            return this.accessibleItems(menu)
        },
        showThreePointsMenu(menu) {
            return this.menuItems(menu).length && !this.menuItems(menu).every(i => i.separator)
        },
        async rowDblClickHandler(event) {
            const {node: { key, form, distributionId, templateId }} = event
            this.distributionId = distributionId;
            this.editRow = key;
            if (this.formPermissionsObject[form.id]
                && (this.formPermissionsObject[form.id][read] || this.formPermissionsObject[form.id][write])) {
                this.editItem();
            }

            if (this.formGroupPermissions.length > 0) {
                if (!this.groups && templateId) {
                    const {data, included: templateIncluded} = await getTemplateData(templateId);
                    this.templateData = data;
                    this.templateIncluded = templateIncluded;
                    this.groups = this.aggregateGroups();
                    let firstGroup = this.groups.find(group => group.id === this.formGroupPermissions[0]);
                    if (firstGroup) {
                        this.editItem(firstGroup.firstWorksheet.id);
                    }
                }
            }

            if (!Object.keys(this.formPermissionsObject).length) {
                this.editItem();
            }
        },
        /** Загружаем раздачи филиалов */
        async rowExpandHandler(node) {
            let lazyNode = {...node};

            let branches = [];

            const { list, included } = await getDistributionListData({
                filter: {
                    'period.id': { '$eq': node.period.id },
                    'template.form.id': { '$eq': this.selectedForm.id },
                    'organization.parentId': { '$eq': node.organization.id }
                },
                include: 'withoutWorksheets'
            });
            if (included) {
                branches = this.aggregateBranches(list, included, node);
            }

            lazyNode.children = branches.sort((a, b) => (a.data.organization > b.data.organization ? 1 : -1));
            this.dataEntryList = this.dataEntryList.map(n => {
                if (n.key === node.key) {
                    n = lazyNode;
                }
                return n;
            });
        },
      /*  async expandAll() {
            for (let node of this.dataEntryList) {
                await this.rowExpandHandler(node);
            }
        },*/
        async toggleRowMenu(event, slotProps) {
            const {node: {key, form, distributionId, period, organization, templateId, branch, data, children }} = slotProps;

            if (key !== this.editRow) {
                this.$refs[`listMenu${this.editRow}`] && this.$refs[`listMenu${this.editRow}`].hide(event);
            }

            this.acceptDistributions = [];
            this.acceptDistributions.push({
                type: "form-distribution",
                id: distributionId
            });

            if (children[0]?.id) {
                children?.forEach(item => {
                    this.acceptDistributions.push({
                        type: 'form-distribution',
                        id: item.distributionId
                    });
                });
            } else {
                const { list } = await getDistributionListData({
                    filter: {
                        'period.id': { '$eq': period.id },
                        'template.form.id': { '$eq': this.selectedForm.id },
                        'organization.parentId': { '$eq': organization.id }
                    },
                    include: 'withoutWorksheets'
                });
                if (list?.length > 0) {
                    list.forEach((distribution) => {
                        this.acceptDistributions.push({
                            type: 'form-distribution',
                            id: distribution.id
                        });
                    });
                }
            }

            this.distributionId = distributionId;
            this.currentTemplateId = templateId;
            this.nodeData = data;

            /** Если раздача в статусе "новый", "в работе", "отклонена" доступна функция "Отправить на проверку" */
            this.canReview = [ 'new', 'in_work', 'declined' ].includes(data.status) && !branch;

            /** Если раздача на проверке доступны функции "Принять" или "Отклонить" */
            if (![ formVerificationRules ].some(p => this.userPermissionsObject[p])) {
                if (data.status) {
                    this.canAccept = data.status === 'sent';
                    this.canDecline = data.status === 'accepted';
                }
            }
            if ([ formReview ].some(p => this.userPermissionsObject[p])) {
                if (data.status) {
                    this.canAccept = data.status !== 'accepted';
                    this.canDecline = data.status === 'accepted';
                }
            }

            /** Только для пользователя с правом formApprove **/
            if ([ formApprove ].some(p => this.userPermissionsObject[p])) {
                this.canApprove = data.status === 'accepted';
                this.canCancel =  data.status === 'approved';
            }

            this.canReport = !!this.currentUser.userPermissions.find((permission) => permission.id === 'formReadOwn');
            if ([ formVerificationRules, formWriteOwn ].some(p => this.userPermissionsObject[p])) {
                this.canReport = true;
            }

            if (this.formPermissionsObject[form.id]
                && !this.formPermissionsObject[form.id][write]
                && !this.formPermissionsObject[form.id][read]) {
                this.canReview = false;
            }
            if (this.formPermissionsObject[form.id]
                && !this.formPermissionsObject[form.id][write]
                && this.formPermissionsObject[form.id][read]) {
                this.canReview = false;
            }
            if (this.formPermissionsObject[form.id]
                && !this.formPermissionsObject[form.id][report]) {
                this.canReport = false;
            }
            if (this.formPermissionsObject[form.id]
                && this.formPermissionsObject[form.id][review]) {
                this.canAccept = data.status !== 'accepted';
                this.canDecline =  data.status === 'accepted';
            }
            if (this.formPermissionsObject[form.id]
                && !this.formPermissionsObject[form.id][review]) {
                this.canAccept = false;
                this.canDecline = false;
            }
            if (this.formPermissionsObject[form.id]) {
                this.canApprove = this.formPermissionsObject[form.id][approve] ? data.status === 'accepted' : false;
                this.canCancel = this.formPermissionsObject[form.id][approve] ? data.status === 'approved' : false;
            }
            if (Object.keys(this.formPermissionsObject).length > 0
                && !this.formPermissionsObject[form.id]) {
                this.canReport = false;
                this.canReview = false;
                this.canAccept = false;
                this.canDecline = false;
                this.canApprove = false;
                this.canCancel = false;
            }

            if (this.acceptGroupPersmissions.length > 0) {
                this.canAccept = data.status !== 'accepted';
                this.canDecline = this.acceptGroupPersmissions ? !this.canAccept : false;
            }

            if (this.approveGroupPersmissions.length > 0) {
                this.canApprove = data.status === 'accepted';
                this.canCancel =  data.status === 'approved';
            }

            if (this.currentTemplateId) {
                const {data, included: templateIncluded} = await getTemplateData(this.currentTemplateId);
                this.templateData = data;
                this.templateIncluded = templateIncluded;
                this.groups = this.aggregateGroups();
                if (this.formGroupPermissions.length > 0) {
                    if (this.groups && this.currentTemplateId) {
                        this.firstGroup = this.groups.find(group => this.formGroupPermissions.includes(group.id));
                    }
                }
            }

            this.editRow = key;
            const menuEl = this.$refs[`listMenu${key}`];

            // Open new popup
            menuEl.toggle(event);
            await this.$nextTick();
            const {y} = event;
            const {$el} = menuEl;

            if ($el?.style) {
                $el.style.top = `${y}px`;
            }
        },
        async sendReview() {
            try {
                this.$root.reachGoal('send-review');

                const { included } = await getTemplateData(this.currentTemplateId);
                this.worksheets = included.filter((item) => item.type === 'form-template-worksheet');
                this.statuses = await getDistributionWorksheetsStatuses(this.distributionId);

                let data = {};
                if (this.worksheets) {
                    let worksheets = {
                        data: []
                    };

                    this.worksheets.forEach((worksheet) => {
                        let statusData = {};
                        if (this.statuses.length > 0) {
                            statusData = this.statuses.find((status) => status.relationships.worksheet.data.id === worksheet.id);
                        }

                        if (statusData && statusData.attributes.status !== 'accepted') {
                            worksheets['data'].push({
                                type: 'form-template-worksheet',
                                id: worksheet.id
                            });
                        }
                    });

                    data = {
                        data: {
                            worksheets: worksheets
                        }
                    };
                }

                await sendDistributionReview(this.distributionId, data);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Форма отправлена на проверку',
                    life: '3200',
                });

                this.$emit('loadingChange', true);
                this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
                this.nodeData.status = 'sent';
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        async showAcceptForm() {
            const { included } = await getTemplateData(this.currentTemplateId);
            this.reviewWorksheets = [];
            this.worksheets = included.filter((item) => item.type === 'form-template-worksheet');
            this.statuses = await getDistributionWorksheetsStatuses(this.distributionId);
            this.formTitle = `${ this.nodeData.name } (${ this.nodeData.periodType })`;

            this.acceptedWorksheetIds = [];
            this.declinedWorksheetIds = [];

            this.worksheets.forEach((worksheet) => {
                if (this.acceptGroupPersmissions?.length > 0 && worksheet.relationships.group.data?.id
                    && !this.acceptGroupPersmissions.includes(worksheet.relationships.group.data.id)) {
                    return;
                }

                let statusData = this.statuses.find((status) => status.relationships.worksheet.data.id === worksheet.id);
                if (statusData) {
                    worksheet.attributes.name = `Лист ${worksheet.attributes.name}`;
                    worksheet.status = this.entryStatuses.find(item => item.value === statusData.attributes.status);
                    if (statusData.attributes.status === 'accepted') {
                        this.acceptedWorksheetIds[worksheet.id] = true;
                        this.declinedWorksheetIds[worksheet.id] = false;
                    } else if (statusData.attributes.status === 'declined') {
                        this.declinedWorksheetIds[worksheet.id] = true;
                        this.acceptedWorksheetIds[worksheet.id] = false;
                    } else {
                        this.acceptedWorksheetIds[worksheet.id] = false;
                        this.declinedWorksheetIds[worksheet.id] = false;
                    }
                } else {
                    worksheet.status = this.entryStatuses[0];
                    this.acceptedWorksheetIds[worksheet.id] = false;
                    this.declinedWorksheetIds[worksheet.id] = false;
                }

                if (worksheet.relationships.group.data?.id) {
                    worksheet.group = this.groups.find((group) => group.id === worksheet.relationships.group.data.id);
                } else {
                    worksheet.group = {
                        id: 'noId',
                        header: 'Листы без группы',
                        lists: [],
                        color: '',
                        sort: -1,
                    }
                }

                if ((this.groups?.length > 0 && worksheet.relationships.group.data?.id)
                    || (!worksheet.relationships.group.data?.id && this.groups?.length === 1 && this.groups[0].id === 'noId')) {
                    this.reviewWorksheets.push(worksheet);
                }
            });

            if (this.groups) {
                this.reviewWorksheets.sort((a, b) => (a.group.id > b.group.id ? -1 : 1));

                let groupStatuses = [];
                this.reviewWorksheets.forEach((worksheet) => {
                    if (!groupStatuses[worksheet.group.id]) {
                        groupStatuses[worksheet.group.id] = [];
                    }
                    if (groupStatuses[worksheet.group.id][0] !== worksheet.status.value) {
                        groupStatuses[worksheet.group.id].push(worksheet.status.value);
                    }
                });

                Object.keys(groupStatuses).forEach((key) => {
                    if (groupStatuses[key].length === 1) {
                        if (groupStatuses[key][0] === 'accepted') {
                            this.acceptedGroupIds[key] = true;
                            this.declinedGroupIds[key] = false;
                        } else if (groupStatuses[key][0] === 'declined') {
                            this.declinedGroupIds[key] = true;
                            this.acceptedGroupIds[key] = false;
                        } else {
                            this.declinedGroupIds[key] = false;
                            this.acceptedGroupIds[key] = false;
                        }
                    } else {
                        this.declinedGroupIds[key] = false;
                        this.acceptedGroupIds[key] = false;
                    }
                });
            }

            this.showAcceptFormDialog = true;
        },

        aggregateGroups() {
            let allWorksheets = [];
            let groups = [];

            this.templateIncluded.forEach((include) => {
                if (include.type === 'form-template-worksheet') {
                    let groupId = include.relationships.group.data?.id;

                    if (groupId) {
                        if (!allWorksheets[groupId]) {
                            allWorksheets[groupId] = [];
                        }

                        allWorksheets[groupId].push(include);
                    } else {
                        if (!allWorksheets['noGroup']) {
                            allWorksheets['noGroup'] = [];
                        }

                        allWorksheets['noGroup'].push(include);
                    }
                }
                if (include.type === 'form-group') {
                    groups.push(include);
                }
            });

            let allGroups = [];
            groups.forEach((group) => {
                if (allWorksheets[group.id]) {

                    allGroups.push({
                        ...group.attributes,
                        id: group.id,
                        header: group.attributes.name,
                        color: group.color || '',
                        firstWorksheet: allWorksheets[group.id][0]
                    });
                }
            });

            if (allWorksheets['noGroup']) {
                allGroups.unshift({
                    id: 'noId',
                    header: 'Листы без группы',
                    color: '',
                    sort: -1,
                });
            }

            return allGroups.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        },

        async approveForm() {
            try {
                await approveDistributionReview({
                    distributions: {
                        data: [{
                            type: "form-distribution",
                            id: this.distributionId
                        }]
                    }
                });

                this.$toast.add({
                    severity: 'success',
                    summary: 'Форма успешно утверждена',
                    life: '3200',
                });

                this.$emit('loadingChange', true);
                this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        async revokeApproval() {
            try {
                await revokeDistributionApproval({
                    distributions: {
                        data: [{
                            type: "form-distribution",
                            id: this.distributionId
                        }]
                    }
                });

                this.$toast.add({
                    severity: 'success',
                    summary: 'Подтверждение результатов проверки формы отменено успешно',
                    life: '3200',
                });

                this.$emit('loadingChange', true);
                this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        closeAcceptFormDialog() {
            this.showAcceptFormDialog = false;
        },

        async acceptForm(acceptedWorksheets = [], declinedWorksheets = [], comments = []) {
            let acceptFormData = [];

            this.reviewWorksheets.forEach((worksheet) => {
                if (acceptedWorksheets[worksheet.id]) {
                    acceptFormData.push({
                        status: 'accepted',
                        comment: comments[worksheet.id],
                        worksheet: {
                            data: {
                                type: "form-distribution-worksheet",
                                id: worksheet.id
                            }
                        }
                    });
                } else if (declinedWorksheets[worksheet.id]) {
                    acceptFormData.push({
                        status: 'declined',
                        comment: comments[worksheet.id],
                        worksheet: {
                            data: {
                                type: "form-distribution-worksheet",
                                id: worksheet.id
                            }
                        }
                    });
                } else {
                    acceptFormData.push({
                        status: 'sent',
                        comment: comments[worksheet.id],
                        worksheet: {
                            data: {
                                type: "form-distribution-worksheet",
                                id: worksheet.id
                            }
                        }
                    });
                }
            });

            const data = {
                distributions: {
                    data: this.acceptDistributions
                },
                review: {
                    data: acceptFormData
                }
            };
            this.$emit('loadingChange', true);
            try {
                const result = await submitDistributionReview(this.distributionId, data);

                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.showAcceptFormDialog = false;
                this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        onPage(event) {
            setTimeout(() => {
                this.$emit('loadingChange', true);
                this.currentPage = event.page + 1;
                this.pageSize = event.rows;
                this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
            }, 1000);
        },
        async clearFilters() {
            this.acceptFilter = false;
            this.filters = {
                name: '',
                organizations: [],
                periods: [],
                subordination: [],
                location: [],
                district: [],
                types: [],
                status: [],
                params: {
                    organization: {
                        operation: '$in',
                        values: []
                    },
                    period: {
                        operation: '$in',
                        values: []
                    }
                }
            };
            this.selectedForm = null;
            this.selectedPeriod = { value: 'week', label: 'Текущая неделя' };
            this.showFilters = false;
            this.currentPage = 1;
            this.pageSize = MAX_PAGE_SIZE;
            this.dataEntryList = [];
            this.distributions = [];
            await this.saveAggregateFilter({ filters: this.filters });
        },
        buildDistributionTree() {
            const allPeriods = this.included.filter(({ type }) => type === 'form-distribution-interval')
            this.formPeriodsFilters = jsonApiListParser(allPeriods).map((i) => i.name);
            const forms = jsonApiListParser(this.included.filter(i => i.type === 'form'), this.included);
            const organizations = jsonApiListParser(this.included.filter(i => i.type === 'organization'), this.included);
            this.dataEntryList = this.distributions.map((distribution) => {
                let
                    template = this.getTemplateData(distribution),
                    period = this.getPeriod(distribution),
                    form = this.getFormData(distribution, template, forms),
                    organization = organizations.find(
                        (organization) => organization.id === distribution.relationships.organization.data.id
                    ),
                    currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60 * 1000
                ;
                this.currentTemplateId = template.id;

                return {
                    template: template,
                    form: form,
                    branch: Object.keys(organization.parent).length > 0,
                    organization: organization,
                    data: {
                        incrementId: organization.incrementId,
                        organization: organization.publicName,
                        type: organization.type ? organization.type.name : '',
                        subordination: organization.subordinateTo ? organization.subordinateTo.name : '',
                        location: organization.location ? organization.location.name : '',
                        district: organization.district ? organization.district.name : '',
                        periodType: period.attributes.name,
                        name: form.name,
                        status: this.getStatusDistribution(distribution),
                        empty: distribution.attributes.empty,
                    },
                    templateId: template.id,
                    children: Object.keys(organization.children).length > 0 ? [{}] : [],
                    key: distribution.id,
                    id: distribution.id,
                    distributionId: distribution.id,
                    menuActive: true,
                    period: period,
                    activeFrom: (new Date(period.attributes.activeFrom)).getTime() + currentTimeZoneOffset,
                    activeTo: (new Date(period.attributes.activeUntil)).getTime() + currentTimeZoneOffset,
                };
            });
        },
        getPeriod(distribution) {
            let periodData = distribution.relationships.period.data;
            if (periodData != null) {
                return this.included.find((period) => period.id === periodData.id);
            }
        },
        getTemplateData(distribution) {
            let templateData = distribution.relationships.template.data;
            if (templateData != null) {
                return this.included.find((template) => template.id === templateData.id);
            }
        },
        getFormData(distribution, template, forms) {
            if (template != null) {
                return forms.find((findForm) => findForm.id === template.relationships.form.data.id);
            }
        },
        getStatusDistribution(distribution) {
            return (distribution.attributes.blocked) ? 'blocked' : distribution.attributes.status;
        },
        getStatus(slotProps) {
            const status = this.entryStatuses.find(item => item.value === slotProps.node.data.status)
            return status || {}
        },
        editItem(worksheetId) {
            if (worksheetId) {
                this.$router.push(`/dataEntry/${ this.distributionId }/${ worksheetId }/`);
            } else {
                this.$router.push(`/dataEntry/${ this.distributionId }/`);
            }
        },
        aggregateBranches(list, included, node) {
            const organizations = jsonApiListParser(included.filter(i => i.type === 'organization'), included);
            const forms = jsonApiListParser(included.filter(i => i.type === 'form'), included);

            let branches = list.map((distribution) => {
                let
                    template = node.template,
                    period = node.period,
                    form = this.getFormData(distribution, template, forms),
                    organization = organizations.find(
                        (organization) => organization.id === distribution.relationships.organization.data.id
                    ),
                    currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60 * 1000
                ;

                return {
                    template: template,
                    form: form,
                    branch: true,
                    organization: organization,
                    data: {
                        organization: organization.publicName,
                        subordination: organization.subordinateTo ? organization.subordinateTo.name : '',
                        location: organization.location ? organization.location.name : '',
                        district: organization.district ? organization.district.name : '',
                        periodType: period.attributes.name,
                        name: form.name,
                        empty: distribution.attributes.empty,
                        status: this.getStatusDistribution(distribution),
                    },
                    templateId: template.id,
                    children: [],
                    key: distribution.id,
                    id: distribution.id,
                    distributionId: distribution.id,
                    menuActive: true,
                    period: period,
                    activeFrom: (new Date(period.attributes.activeFrom)).getTime() + currentTimeZoneOffset,
                    activeTo: (new Date(period.attributes.activeUntil)).getTime() + currentTimeZoneOffset,
                };
            });

            return branches;
        },
        onInputKeydown() {
            clearTimeout(this.filterInputTimeout);
            this.filterInputTimeout = setTimeout(() => {
                this.currentPage = 1;
                this.pageSize = MAX_PAGE_SIZE;
                this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
            }, 500)
        },

        clearData() {
            this.dataEntryList = null;
            this.acceptFilter = false;
        },

        selectedMainFilter() {
            clearTimeout(this.filterInputTimeout);
            this.filterInputTimeout = setTimeout(() => {
                this.currentPage = 1;
                this.pageSize = MAX_PAGE_SIZE;
                this.filters = {
                    name: '',
                    organizations: [],
                    periods: [],
                    subordination: [],
                    location: [],
                    district: [],
                    types: [],
                    status: [],
                    params: {
                        organization: {
                            operation: '$in',
                            values: []
                        },
                        period: {
                            operation: '$in',
                            values: []
                        }
                    }
                };
                if (this.selectedForm?.id) {
                    this.fetchFormPeriods();
                    this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
                    this.getGroupPermissions();
                }
            }, 500);
        },
        selectedOrganizations($event) {
            const prev = this.filters.params.organization.values.length;

            if (this.filters.organizations?.length > 0) {
                let organizationsCount = this.filters.organizations.length;
                this.filters.params.organization.values = $event.value;

                if (this.filters.params.organization.operation === '$ne') {
                    this.filters.params.organization.values = this.organizationsList
                        .filter(item => !$event.value.includes(item.id))
                        .map((item) => {
                            return item.id;
                        });
                }
                if (organizationsCount === this.selectedAllOrganizations) {
                    this.filters.params.organization.operation = '$ne';
                    this.filters.params.organization.values = [];
                }
            } else {
                this.filters.params.organization.operation = '$in';
                this.filters.params.organization.values = [];
            }

            if (prev === this.filters.params.organization.values.length) {
                return;
            }

            this.selectedFilter();
        },
        selectedPeriods($event) {
            const prev = this.filters.params.period.values.length;

            if (this.filters.periods?.length > 0) {
                let periodsCount = this.filters.periods.length;
                this.filters.params.period.values = $event.value;

                if (this.filters.params.period.operation === '$ne') {
                    this.filters.params.period.values = this.periods
                        .filter(item => !$event.value.includes(item.id))
                        .map((item) => {
                            return item.id;
                        });
                }
                if (periodsCount === this.selectedAllPeriods) {
                    this.filters.params.period.operation = '$ne';
                    this.filters.params.period.values = [];
                }
            } else {
                this.filters.params.period.operation = '$in';
                this.filters.params.period.values = [];
            }

            if (prev === this.filters.params.period.values.length) {
                return;
            }

            this.selectedFilter();
        },
        selectedFilter() {
            clearTimeout(this.filterInputTimeout);
            this.filterInputTimeout = setTimeout(() => {
                this.currentPage = 1;
                this.pageSize = MAX_PAGE_SIZE;
                if (this.selectedForm) {
                    if (this.filters.organizations && !this.filters.organizations.length) {
                        this.fetchOrganizations();
                    }

                    this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
                }
            }, 500);
        },
        async updateDistributionList(form, period, filters = {}) {
            if (!form.id) {
                this.acceptFilter = false;
                return;
            }

            if (period === 'range' && (!this.rangeStart || !this.rangeEnd)) {
                return;
            }

            this.$emit('loadingChange', true);
            this.acceptFilter = true;
            try {
                const currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60000;

                await this.saveAggregateFilter({
                    form: this.selectedForm,
                    period: this.selectedPeriod,
                    rangeStart: this.rangeStart ? new Date(this.rangeStart.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) : null,
                    rangeEnd: this.rangeEnd ? new Date(this.rangeEnd.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) : null,
                    filters: filters
                });

                this.distributionFilter = {};
                const today = new Date();
                const currentYear = today.getFullYear();
                const currentMonth = today.getMonth() + 1;

                if (period === 'all') {
                    this.distributionFilter = { 'template.form.id': { '$eq': form.id } };
                }

                if (period === 'day') {
                    this.distributionFilter = {
                        'period.activeFrom': { '$eq': `${today.toISOString().substring(0, 10)}T00:00:00Z` },
                        'period.activeUntil': { '$eq': `${today.toISOString().substring(0, 10)}T23:59:59Z` },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (period === 'pastMonth') {
                    let lastMonthDate = new Date();
                    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1, 1);
                    this.distributionFilter = {
                        'period.activeFrom': {
                            '$gte': `${new Date(lastMonthDate).toISOString().substring(0, 10)}T00:00:00Z`
                        },
                        'period.activeUntil': {
                            '$lte': `${new Date(currentYear, currentMonth - 1, 1).toISOString().substring(0, 10)}T23:59:59Z`
                        },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (period === 'pastYear') {
                    this.distributionFilter = {
                        'period.activeFrom': { '$gte': `${new Date(currentYear - 1, 0, 1).toISOString().substring(0, 10)}T00:00:00Z` },
                        'period.activeUntil': { '$lte': `${new Date(currentYear, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (period === 'future') {
                    this.distributionFilter = {
                        'period.activeFrom': { '$gt': today.toISOString().substring(0, 10) },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (period === 'week') {
                    let firstDayOfWeek = today.getDate() - today.getDay() + 1;
                    let lastDayOfWeek = today.getDate() - today.getDay() + 7;

                    this.distributionFilter = {
                        'period.activeFrom': {
                            '$gte': new Date(today.setDate(firstDayOfWeek)).toISOString().substring(0, 10)
                        },
                        'period.activeUntil': {
                            '$lte': new Date(today.setDate(lastDayOfWeek)).toISOString().substring(0, 10)
                        },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (period === 'month') {
                    today.setDate(1);

                    this.distributionFilter = {
                        'period.activeFrom': {
                            '$gte': today.toISOString().substring(0, 10)
                        },
                        'period.activeUntil': {
                            '$lte': new Date(currentYear, currentMonth, 31).toISOString().substring(0, 10)
                        },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (period === 'year') {
                    this.distributionFilter = {
                        'period.activeFrom': { '$gte': `${new Date(currentYear, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        'period.activeUntil': { '$lte': `${new Date(currentYear + 1, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (period === 'range' && this.rangeStart && this.rangeEnd) {
                    this.distributionFilter = {
                        'period.activeFrom': {
                            '$gte': `${ new Date(this.rangeStart.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T00:00:00Z`
                        },
                        'period.activeUntil': {
                            '$lte': `${ new Date(this.rangeEnd.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T23:59:59Z`
                        },
                        'template.form.id': { '$eq': form.id }
                    };
                }

                if (filters.name && filters.name?.length > 0) {
                    this.distributionFilter['organization.name.shortName'] = { '$ilike': filters.name };
                }

                if (filters.incrementId && filters.incrementId.length) {
                    this.distributionFilter['organization.incrementId'] = { $eq: filters.incrementId };
                } else {
                    delete this.distributionFilter['organization.incrementId'];
                }

                if (filters.params.organization.values && filters.params.organization.values.length) {
                    if (filters.params.organization.values.length === 1) {
                        this.distributionFilter['organization.id'] = filters.params.organization.operation === '$in'
                            ? { '$eq': filters.params.organization.values[0] }
                            : { '$ne': filters.params.organization.values[0] };
                    } else {
                        if (filters.params.organization.operation === '$in') {
                            this.distributionFilter['organization.id'] = { '$in': filters.params.organization.values };
                        } else {
                            this.distributionFilter['organization.id'] = { '$nin': filters.params.organization.values };
                        }
                    }
                }

                if (filters.params.period.values && filters.params.period.values.length) {
                    if (filters.params.period.values.length === 1) {
                        this.distributionFilter['period.id'] = filters.params.period.operation === '$in'
                            ? { '$eq': filters.params.period.values[0] }
                            : { '$ne': filters.params.period.values[0] };
                    } else {
                        if (filters.params.period.operation === '$in') {
                            this.distributionFilter['period.id'] = { '$in': filters.params.period.values };
                        } else {
                            this.distributionFilter['period.id'] = { '$nin': filters.params.period.values };
                        }
                    }
                    delete(this.distributionFilter['period.activeFrom']);
                    delete(this.distributionFilter['period.activeUntil'])
                }

                if (filters.subordination && filters.subordination.length) {
                    this.distributionFilter['organization.subordinateTo.id'] = { '$in': filters.subordination };
                } else {
                    delete this.distributionFilter['organization.subordinateTo.id'];
                }

                if (filters.location && filters.location.length) {
                    this.distributionFilter['organization.location.id'] = { '$in': filters.location };
                } else {
                    delete this.distributionFilter['organization.location.id'];
                }

                if (filters.district && filters.district.length) {
                    this.distributionFilter['organization.district.id'] = { '$in': filters.district };
                } else {
                    delete this.distributionFilter['organization.district.id'];
                }

                if (filters.types && filters.types.length) {
                    this.distributionFilter['organization.type.id'] = { '$in': filters.types };
                } else {
                    delete this.distributionFilter['organization.type.id'];
                }

                if (filters.status && filters.status.length) {
                    this.distributionFilter['status'] = { '$in': filters.status };
                } else {
                    delete this.distributionFilter['status'];
                }

                if (this.showArchiveOrganizations) {
                    this.distributionFilter['organization.status.activeUntil'] = { '$eq': null };
                }

                if (!this.showAllDistributions) {
                    const { organizations } = await getOrganizations({
                        pageSize: 20,
                        filter: {
                            'parentId': {'$eq': null}
                        }
                    });
                    this.organizationsList = jsonApiListParser(organizations);
                    this.selectedAllOrganizations = this.organizationsList.length;
                    this.distributionFilter['organization.id'] = getOrganizationId();
                } else {
                    /** Изначально загружаем раздачи только по головным организациям */
                    this.distributionFilter['organization.parentId'] = { '$eq': null };
                }

                const { list, included, meta } = await getDistributionListData({
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    filter: this.distributionFilter,
                    sort: 'organization.incrementId',
                    include: 'withoutWorksheets'
                });

                this.included = included;
                this.distributions = list;
                this.totalRecords = meta.pagination.total;

                this.buildDistributionTree();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
           // await this.expandAll();
        },

        async startUnloading(formDistributionId, reportId) {
            try {
                this.$root.reachGoal('upload');
                this.$emit('loadingChange', true);
                const result = await sendUnloadingData(formDistributionId, reportId);

                if (result.status === 204)
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Запрос на генерацию отчета поставлен в очередь, перейдите в раздел выгрузок',
                        life: 2500,
                    });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        async getData() {
            this.$emit('loadingChange', true);

            try {
                 await Promise.all([
                    getAllForms({
                        relationShip: true,
                        filter: { archive: false, active: true }
                    }).then(({ forms, included }) => {
                        this.formList = jsonApiListParser(forms, included);
                    }),
                ]);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        async createAggregate(formDistributionId, group) {
            this.$emit('loadingChange', true);
            try {
                const { data: aggregateResult } = await aggregateDistribution(formDistributionId);

                let routeData = {};

                if (group && group.firstWorksheet.id) {
                    routeData = this.$router.resolve({
                        path: `/aggregate/${this.currentTemplateId}/${aggregateResult.data.id}/${formDistributionId}/${ group.firstWorksheet.id }`,
                    });
                } else {
                    routeData = this.$router.resolve({
                        path: `/aggregate/${this.currentTemplateId}/${aggregateResult.data.id}/${formDistributionId}/`,
                    });
                }
                this.$root.reachGoal('create-aggregate');
                window.open(routeData.href, '_blank');
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },
        async aggregateAll() {
            this.$emit('loadingChange', true);
            try {
                let aggregateData = this.distributionFilter;
                let aggregateFilter = {
                    filter: this.distributionFilter
                }

                /** Если выбраны организации, то в свод включаем также филиалы этих организаций */
                delete(aggregateFilter.filter['organization.parentId']);
                const organizationFilter = this.distributionFilter['organization.id'];
                if (this.distributionFilter['organization.id']) {
                    delete(aggregateData['organization.parentId']);
                    delete(aggregateData['organization.id']);
                    aggregateFilter = {
                        filter: {
                            '$and': [
                                {
                                    '$or': [
                                        { 'organization.id': organizationFilter },
                                        { 'organization.parentId': organizationFilter }
                                    ]
                                },
                                aggregateData
                            ]
                        }
                    }
                }

                const { data: aggregateResult } = await aggregateByFilter(this.currentTemplateId, aggregateFilter);
                let routeData = this.$router.resolve({
                    path: `/aggregate/${ this.currentTemplateId }/${ aggregateResult.data.id }/`,
                });
                this.$root.reachGoal('aggregate-all');
                window.open(routeData.href, '_blank');
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        async getGroupPermissions() {
            const {
                data: permissionData,
                included: permissionIncluded
            } = await getFormGroupPermissions(this.currentUser.id, `formId[]=${ this.selectedForm.id }`);

            if (permissionData) {
                let formGroupPermissions = jsonApiListParser(permissionData, permissionIncluded);
                formGroupPermissions.forEach(({ group, operation }) => {
                    if (['read', 'write'].includes(operation) && !this.formGroupPermissions.includes(group.id)) {
                        this.formGroupPermissions.push(group.id);
                    }
                    if (['review'].includes(operation) && !this.acceptGroupPersmissions.includes(group.id)) {
                        this.acceptGroupPersmissions.push(group.id);
                    }
                    if (['approve'].includes(operation) && !this.approveGroupPersmissions.includes(group.id)) {
                        this.approveGroupPersmissions.push(group.id);
                    }
                });
            }
        },
        async fetchFormPeriods() {
            try {
                this.periodFilter = {};
                const today = new Date();
                const currentYear = today.getFullYear();
                const currentMonth = today.getMonth() + 1;
                const currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60000;

                if (this.selectedPeriod.value === 'all') {
                    this.periodFilter = { active: { '$eq': true } };
                }

                if (this.selectedPeriod.value === 'past') {
                    this.periodFilter = {
                        'activeUntil': { '$lte': today.toISOString().substring(0, 10) },
                        active: { '$eq': true }
                    };
                }

                if (this.selectedPeriod.value === 'pastMonth') {
                    let lastMonthDate = new Date();
                    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1, 1);

                    this.periodFilter = {
                        'activeFrom': { '$gte': `${new Date(lastMonthDate).toISOString().substring(0, 10)}T00:00:00Z` },
                        'activeUntil': { '$lte': `${new Date(currentYear, currentMonth - 1, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        active: { '$eq': true }
                    };
                }


                if (this.selectedPeriod.value === 'pastYear') {
                    this.periodFilter = {
                        'activeFrom': { '$gte': `${ new Date(currentYear - 1, 0, 1).toISOString().substring(0, 10) }T23:59:59Z` },
                        'activeUntil': { '$lte': `${ new Date(currentYear, 0, 1).toISOString().substring(0, 10) }T23:59:59Z` },
                        active: { '$eq': true }
                    };
                }

                if (this.selectedPeriod.value === 'future') {
                    this.periodFilter = {
                        'activeFrom': { '$gt': today.toISOString().substring(0, 10) },
                        active: { '$eq': true }
                    };
                }

                if (this.selectedPeriod.value === 'week') {
                    let firstDayOfWeek = today.getDate() - today.getDay() + 1;
                    let lastDayOfWeek = today.getDate() - today.getDay() + 7;

                    this.periodFilter = {
                        'activeFrom': {
                            '$gte': new Date(today.setDate(firstDayOfWeek)).toISOString().substring(0, 10)
                        },
                        'activeUntil': {
                            '$lte': new Date(today.setDate(lastDayOfWeek)).toISOString().substring(0, 10)
                        },
                        active: { '$eq': true }
                    };
                }

                if (this.selectedPeriod.value === 'month') {
                    today.setDate(1);

                    this.periodFilter = {
                        'activeFrom': {
                            '$gte': today.toISOString().substring(0, 10)
                        },
                        'activeUntil': {
                            '$lte': new Date(currentYear, currentMonth, 31).toISOString().substring(0, 10)
                        },
                        active: { '$eq': true }
                    };
                }

                if (this.selectedPeriod.value === 'year') {
                    this.periodFilter = {
                        'activeFrom': { '$gte': `${new Date(currentYear, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        'activeUntil': { '$lte': `${new Date(currentYear + 1, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        active: { '$eq': true }
                    };
                }

                if (this.selectedPeriod.value === 'range' && this.rangeStart && this.rangeEnd) {
                    this.periodFilter = {
                        'activeFrom': {
                            '$gte': `${ new Date(this.rangeStart.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T00:00:00Z`
                        },
                        'activeUntil': {
                            '$lte': `${ new Date(this.rangeEnd.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T23:59:59Z`
                        },
                        active: { '$eq': true }
                    };
                }

                await Promise.all([
                    getAllIntervalPeriods({
                        intervalId: this.selectedForm.distributionInterval.id,
                        filter: this.periodFilter
                    }).then(({ data: allIntervalPeriods, included: incPeriods }) => {
                        this.periods = jsonApiListParser(allIntervalPeriods, incPeriods);
                        this.selectedAllPeriods = this.periods.length;
                    }),
                    this.fetchOrganizations(),
                ]);
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        async fetchOrganizations() {
            try {
                if (!this.showAllDistributions) {
                    return;
                }

                let organizationFilter = {
                    'period.activeFrom': this.periodFilter['activeFrom'],
                    'period.activeUntil': this.periodFilter['activeUntil'],
                    'template.form.id': { '$eq': this.selectedForm.id },
                    'organization.parentId': { '$eq': null }
                };

                if (this.filters.params.period.values && this.filters.params.period.values.length) {
                    if (this.filters.params.period.values.length === 1) {
                        organizationFilter['period.id'] = this.filters.params.period.operation === '$in'
                            ? { '$eq': this.filters.params.period.values[0] }
                            : { '$ne': this.filters.params.period.values[0] };
                    } else {
                        if (this.filters.params.period.operation === '$in') {
                            organizationFilter['period.id'] = { '$in': this.filters.params.period.values };
                        } else {
                            organizationFilter['period.id'] = { '$nin': this.filters.params.period.values };
                        }
                    }
                    delete(organizationFilter['period.activeFrom']);
                    delete(organizationFilter['period.activeUntil'])
                }

                if (this.filters.subordination && this.filters.subordination.length) {
                    organizationFilter['organization.subordinateTo.id'] = { '$in': this.filters.subordination };
                }

                if (this.filters.location && this.filters.location.length) {
                    organizationFilter['organization.location.id'] = { '$in': this.filters.location };
                }

                if (this.filters.district && this.filters.district.length) {
                    organizationFilter['organization.district.id'] = { '$in': this.filters.district };
                }

                if (this.filters.types && this.filters.types.length) {
                    organizationFilter['organization.type.id'] = { '$in': this.filters.types };
                }

                if (this.filters.status && this.filters.status.length) {
                    organizationFilter['status'] = { '$in': this.filters.status };
                }

                const { data: { data, included } } = await findOrganizations({
                    filter: organizationFilter,
                    include: 'location,district,type,subordinateTo,parent.district,parent.location,parent.subordinateTo,parent.parent'
                });
                this.organizationsList = jsonApiListParser(data);
                const locations = (included || []).filter(i => i.type === 'organization-location');
                const subordinations = (included || []).filter(i => i.type === 'organization-structure');
                const types = (included || []).filter(i => i.type === 'organization-type');

                if ((this.filters.subordination?.length === 0 && this.filters.location?.length === 0 && this.filters.district?.length === 0 && this.filters.types?.length === 0)
                    || !this.locations
                    || ! this.subordinations) {
                    this.locations = jsonApiListParser(locations);
                    this.subordinations = jsonApiListParser(subordinations);
                    this.types = jsonApiListParser(types);
                }
                this.selectedAllOrganizations = this.organizationsList.length;
            } catch (error) {
                this.$requestError(error.message);
            }
        }
    },
    async created() {
        await this.getData();

        /** При открытии страницы брать настройки фильтра из store */
        await this.initAggregateFilter();
        if (this.aggregateFilter && this.aggregateFilter !== {}) {
            this.selectedForm = this.aggregateFilter.form;
            this.selectedPeriod = this.aggregateFilter.period ? this.aggregateFilter.period : this.selectedPeriod;
            this.rangeStart = this.aggregateFilter.rangeStart ? new Date(this.aggregateFilter.rangeStart) : null;
            this.rangeEnd = this.aggregateFilter.rangeEnd ? new Date(this.aggregateFilter.rangeEnd) : null;
            this.filters = this.aggregateFilter.filters;
            if (this.selectedForm?.id) {
                //await this.fetchFormPeriods();
                //this.updateDistributionList(this.selectedForm, this.selectedPeriod.value, this.filters);
                //this.getGroupPermissions();
            }
        }
    }
};
// 1722
</script>

<style lang="scss" scoped>
.layout-content-wrapper .page-container {
    padding: 16px 16px 0 !important;
}
.elementMargin {
    margin-top: 20px;
}
.minusMargin {
    margin: -20px -16px;
    background-color: #eaeaea;
}
.minusMargin2 {
    background-color: #eaeaea;
}
.card {
    padding: 0;
    min-height: 600px;
    flex: 1 0 auto;
    max-height: 100%;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
}
.p-col-custom {
    width: calc(100% - 273px);
}
.p-col-customPad {
    background-color: #fff;
    padding: 0 16px !important;
    border-radius: 3px;
}
.p-datatable-customers {
    ::v-deep {
        .p-treetable {
            &-header {
                background: #ffffff;
                border: unset;
                padding: 0 0 1rem 0;
                .table-active-period{
                    margin-top: 12px;
                    .p-column-filter {
                        margin-right: 16px;
                    }
                }
            }
            &-thead {
                th.p-filter-column {
                    span {
                        line-height: unset;
                    }
                    &:hover {
                        background: unset !important;
                    }
                }
                & {
                    tr {
                        &:nth-child(2) {
                            height: unset;
                            max-height: 44px;
                        }
                        th {
                            background: #E0E0E0;
                            border: unset;
                            overflow: unset !important;
                            overflow-x: hidden;
                        }
                    }
                }
            }
            &-tbody {
                tr {
                    height: 44px !important;
                    &:not(.p-treetable-emptymessage):hover {
                        background: #a5dca8 !important;
                    }
                    &:nth-child(even) {
                        background: #eaeaea !important;
                        &:hover {
                            background: #a5dca8 !important;
                        }
                    }
                    td {
                        padding: 0 0 0 1rem;
                        &:last-child {
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }
        .p-panel-header-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &:hover {
                background: rgba(135, 148, 163, 0.25);
            }
        }
        .entry {
            &-header {
                &-checkbox {
                    width: calc(3vw + 16.2px);
                }
                &-name {
                    width: calc(10vw + 16.2px);
                }
                &-org {
                    width: calc(8vw + 16.2px);
                }
                &-period {
                    width: calc(7vw + 16.2px);
                }
                &-status {
                    width: calc(5vw + 16.2px);
                }
                &-empty {
                    width: 20px;
                }
                &-id {
                    width: 78px;
                }
            }
        }
    }
}
::v-deep .customDropDown {
    width: 550px;
    .p-dropdown-items-wrapper {
        max-height: 350px !important;
        width: 550px !important;
        .p-dropdown-item {
            div {
                white-space: normal !important;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
::v-deep span.p-menuitem-icon {
    min-width: 24px;
}
::v-deep {
    label.custom-radio input {
        display: none;
    }
    label.custom-radio input+div {
        content: "\a";
        background: #ffffff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: black;
        border: 2px solid #EAEAEA;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .radio-icon {
        width: 12px;
        height: 12px;
        transition-duration: 0.2s;
        background-color: #ffffff;
        border-radius: 50%;
    }
    label.custom-radio input:checked+div {
        background: #388E3C;
        border-color: #2d7230;
        color: #ffffff;
    }
    label.custom-radio.decline input:checked+div {
        background: #FF6767;
        border-color: #FF6767;
        color: #ffffff;
    }
    .p-inputtextarea {
        margin: 4px 0;
    }
}
::v-deep .p-calendar .p-inputtext {
    width: 100px;
}
::v-deep .p-calendar-w-btn .p-datepicker-trigger {
    margin-right: 16px;
}
::v-deep .p-datepicker {
    width: 300px;
    .p-timepicker {
        height: 0 !important;
        div {
            display: none;
        }
    }
}
::v-deep .multiselect-custom {
    min-width: 98%;
    max-width: 98%;
    margin-top: 4px;

    .p-multiselect-label {
        color: #8C8C8C;
        font-size: 13px;
        grid-template-columns: none;
    }

    .p-multiselect-token {
        align-items: center;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        .p-multiselect-token-label {
            margin-right: 0.25rem;
            white-space: normal;
        }
    }
    .p-multiselect-trigger {
        width: 1.3rem;
    }
    .p-multiselect-item {
        width: 340px;
        min-width: 100%;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
        right: 1.2rem;
    }
}
</style>
